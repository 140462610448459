import { graphql } from "@/gql";
import { gql, useQuery } from "@apollo/client";

export const FETCH_ORGANIZATION_BY_IDS = graphql(`
  query FetchOrganizationsByIds($input: FetchOrganizationsByIdsInput!) {
    fetchOrganizationsByIds(input: $input) {
      alias
      id
      logo
      name
    }
  }
`);

export const useFetchOrganizationbyIds = (ids: string[]) => {
  const response = useQuery(FETCH_ORGANIZATION_BY_IDS, {
    variables: {
      input: {
        ids: ids,
      },
    },
  });
  return response;
};

// onClick={() =>
//   router.push(
//     `/organization/${getOrgData(org)?.alias}/${
//       getOrgData(org)?.id
//     }/overview`
//   )
// }
