import { PostComment } from "@/components/PostComment";
import { Box, Text, Row, PrimaryHoverBox } from "@/components/styled";
import Image from "next/image";
import { PostsCardFeed } from "../../PostsCardFeed";
import { useGetPostDetails } from "@/hooks/useGetPostDetails";
import { ExpandedCard } from "@/components/ExpandedCard/ExpandedCard";
import { useFetchAllPosts } from "@/app/auth/hooks/useFetchAllPosts";
import { ExpandedSharedCard } from "./Components/SharedPost/ExpandedSharedCard";
import { useState } from "react";
export const ShareWithDetails = [
  {
    logo: "/assets/images/svgimages/sharefbLogo.svg",
    name: "Facebook",
    getShareUrl: (extractedPostSlug: string) =>
      `https://www.facebook.com/sharer/sharer.php?u=${window.location.origin}/post/${extractedPostSlug}`,
  },
  {
    logo: "/assets/images/svgimages/shareTwitterLogo.svg",
    name: "Twitter",
    getShareUrl: (extractedPostSlug: string) =>
      `https://twitter.com/intent/tweet?&url=${window.location.origin}/post/${extractedPostSlug}`,
  },
  {
    logo: "/assets/images/svgimages/shareLinkedinLogo.svg",
    name: "Linkedin",
    getShareUrl: (extractedPostSlug: string) =>
      `https://www.linkedin.com/sharing/share-offsite/?url=${window.location.origin}/post/${extractedPostSlug}`,
  },

  {
    logo: "/assets/images/svgimages/shareCopyLinkLogo.svg",
    name: "Copy Link",
    getShareUrl: (extractedPostSlug: string) =>
      `${window.location.origin}/post/${extractedPostSlug}`,
  },
];

export const ShareModal = ({ postId }: { postId: string }) => {
  const { data } = useGetPostDetails(postId);
  const [copiedLink, setCopiedLink] = useState(false);

  const extractedPost = data?.fetchPostDetail;

  const extractedPostSlug = postId;

  const handleShare = (platform: any) => {
    if (platform.name === "Copy Link") {
      navigator.clipboard
        .writeText(platform.getShareUrl(extractedPostSlug))
        .then(() => {
          setCopiedLink(true);
          setTimeout(() => setCopiedLink(false), 2000);
        })
        .catch((err) => console.error("Failed to copy link: ", err));
    } else {
      window.open(platform.getShareUrl(extractedPostSlug), "_blank");
    }
  };

  return (
    <Box>
      <Text
        color="primary"
        textAlign={"center"}
        size={["16px", "25px"]}
        weight="bold"
        pt={"xl"}
        pb="6xl"
      >
        Share With
      </Text>
      <Row justifyContent={"space-around"} pb="xl">
        {ShareWithDetails.map((detail, index) => (
          <PrimaryHoverBox
            key={index}
            onClick={() => handleShare(detail)}
            style={{ cursor: "pointer" }}
            justifyContent={"center"}
            alignItems={"center"}
            px={"sm"}
            py={"sm"}
            borderRadius={"sm"}
          >
            <Image
              src={detail?.logo ?? ""}
              alt={"logo"}
              width={35}
              height={35}
            />
            <Text size={["12px", "14px"]} pt={"md"}>
              {detail?.name === "Copy Link" && copiedLink
                ? "Copied!"
                : detail?.name}
            </Text>
          </PrimaryHoverBox>
        ))}
      </Row>
    </Box>
  );
};
