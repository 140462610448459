// export function getRelativePostTime(dateString: Date): string {
//   const date: Date = new Date(dateString);
//   const now: Date = new Date();
//   const diffInSeconds: number = Math.floor(
//     (now.getTime() - date.getTime()) / 1000
//   );

//   if (diffInSeconds < 60) {
//     return `${diffInSeconds} sec${diffInSeconds !== 1 ? "s" : ""} ago`;
//   }

//   const diffInMinutes: number = Math.floor(diffInSeconds / 60);
//   if (diffInMinutes < 60) {
//     return `${diffInMinutes} min${diffInMinutes !== 1 ? "s" : ""} ago`;
//   }

//   const diffInHours: number = Math.floor(diffInMinutes / 60);
//   if (diffInHours < 24) {
//     return `${diffInHours} hr${diffInHours !== 1 ? "s" : ""} ago`;
//   }

//   const diffInDays: number = Math.floor(diffInHours / 24);
//   if (diffInDays < 30) {
//     return `${diffInDays} day${diffInDays !== 1 ? "s" : ""} ago`;
//   }

//   const diffInMonths: number = Math.floor(diffInDays / 30);
//   if (diffInMonths < 12) {
//     return `${diffInMonths} month${diffInMonths !== 1 ? "s" : ""} ago`;
//   }

//   const diffInYears: number = Math.floor(diffInMonths / 12);
//   return `${diffInYears} year${diffInYears !== 1 ? "s" : ""} ago`;
// }

export function getRelativePostTime(dateString: Date): string {
  const date: Date = new Date(dateString);
  const now: Date = new Date();
  const diffInSeconds: number = Math.floor(
    (now.getTime() - date.getTime()) / 1000
  );

  if (diffInSeconds < 60) {
    return `${diffInSeconds}s`;
  }

  const diffInMinutes: number = Math.floor(diffInSeconds / 60);
  if (diffInMinutes < 60) {
    return `${diffInMinutes}m`;
  }

  const diffInHours: number = Math.floor(diffInMinutes / 60);
  if (diffInHours < 24) {
    return `${diffInHours}h`;
  }

  const diffInDays: number = Math.floor(diffInHours / 24);
  if (diffInDays < 30) {
    return `${diffInDays}d`;
  }

  const diffInMonths: number = Math.floor(diffInDays / 30);
  if (diffInMonths < 12) {
    return `${diffInMonths}mo`;
  }

  const diffInYears: number = Math.floor(diffInMonths / 12);
  return `${diffInYears}y`;
}
