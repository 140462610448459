"use client";
import React, { useEffect, useRef, useState } from "react";
import { useRouter } from "next/navigation";
import Link from "next/link";
import { useUser } from "@/hooks/useUser";
import { CardHeader } from "../Components/CardHeader";
import { Box, Button, CenterBox, Row, Text } from "../../styled";
import { BASE_COLORS } from "@/theme";
import { GenericPostType } from "@/utils/types";
import styled from "styled-components";
import { CardFooterV2 } from "../Components/CardFooter/CardFooterV2";
import { CardPremiumOverlay } from "@/components/CardPremiumOverlay";
import { PostStatus } from "@/gql/graphql";
import { useIsLoggedIn } from "@/hooks/useIsLoggedIn";
import { logPostToAnalytics } from "@/lib/gtag";
import ReplyIcon from "@/public/assets/icons/backIcon.svg";
import Image from "next/image";
import { CardHeaderV2 } from "../Components/CardHeader/CardHeaderV2";
import { DeletedByAdminOverlay } from "../Components/DeletedByAdminOverlay";

const StyledLink = styled(Link)`
  color: ${BASE_COLORS.primary};
  text-decoration: none;
  cursor: pointer;
`;

type EntityItem = {
  mentionedId: string;
  [key: string]: string;
  mentionType: string;
};

export const PostsCardTextV2: React.FC<GenericPostType> = ({
  schoolLogo,
  tags,
  cardComments,
  universityName,
  hashtags,
  postBy,
  upVotes,
  viewCount,
  downVotes,
  comments,
  savedPosts,
  title,
  id,
  createdAt,
  isActiveReply,
  authorId,
  status,
  isDeletedFeed,
  isReply,
  type,
}) => {
  const { data } = useUser();
  const router = useRouter();
  const postRef = useRef<HTMLDivElement>(null);
  const [blurEnabled, setBlurEnabled] = useState(
    status === PostStatus.DeletedByPremiumUser
  );
  const [justDeleted, setJustDeleted] = useState(false);

  const disableBlur = () => {
    setBlurEnabled(false);
  };

  const enableBlur = () => {
    setBlurEnabled(true);
  };

  const handlePostDelete = (PostId: string) => {
    if (PostId) setJustDeleted(true);
  };

  const getActivityStatus = (activityType: string) =>
    data?.me?.postActivities.some(
      (activity: { postId: string; type: string }) =>
        activity.postId === id && activity.type === activityType
    ) || false;

  const alreadyUpVoted = getActivityStatus("UPVOTE");
  const alreadyDownVoted = getActivityStatus("DOWNVOTE");
  const alreadyBookMarked = getActivityStatus("BOOKMARK");
  const { isLoggedin } = useIsLoggedIn();

  const text = (cardComments as { text?: string })?.text || "";
  const formattedCardComments = text.slice(0, 405);

  const mergedArray = [...tags, ...hashtags].sort(
    (a, b) => a.startIndex - b.startIndex
  );
  const extractedMentions = tags.map((item: any) => {
    return {
      tag: text.slice(item.startIndex, item.endIndex),
      mentionId: item.mentionedId,
      mentionType: item.mentionType,
      endIndex: item.endIndex,
      startIndex: item.startIndex,
    };
  });

  const renderContent = () => {
    let lastIndex = 0;
    const result = [];

    mergedArray.forEach((item, index) => {
      if (item.startIndex > lastIndex) {
        result.push(text.slice(lastIndex, item.startIndex));
      }

      const mention = text.slice(item.startIndex, item.endIndex);

      const isHashtag = hashtags.includes(item);
      const mentionLink =
        !isHashtag && (item as unknown as EntityItem).mentionType === "SCHOOL"
          ? `/school/${universityName}/overview`
          : `/organization/${universityName}/${
              (item as unknown as EntityItem).mentionedId
            }/overview`;

      const linkHref = isHashtag
        ? `/search?tab=posts&query=${mention.slice(1)}`
        : mentionLink;

      result.push(
        <StyledLink
          key={index}
          href={linkHref}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {mention}
        </StyledLink>
      );
      lastIndex = item.endIndex;
    });

    if (lastIndex < formattedCardComments.length) {
      result.push(formattedCardComments.slice(lastIndex));
    }

    return result;
  };

  const renderPremiumOverlayIfNeeded = () => {
    if (isDeletedFeed) return null;
    if (status === PostStatus.DeletedByPremiumUser && blurEnabled) {
      return <CardPremiumOverlay disableBlur={disableBlur} />;
    } else if (status === PostStatus.DeletedByAdmin) {
      return <DeletedByAdminOverlay disableBlur={disableBlur} />;
    } else return null;
  };

  //Google Custom Event In useEffect
  // useEffect(() => {
  //   viewPost(id, title);
  // }, [id, title]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            logPostToAnalytics(id, type);
            observer.disconnect(); // Stop observing once the post is in the viewport
          }
        });
      },
      { threshold: 0.5 } // Trigger when 50% of the post is visible
    );

    if (postRef.current) {
      observer.observe(postRef.current);
    }
    return () => {
      if (postRef.current) {
        observer.unobserve(postRef.current);
      }
    };
  }, [id, title]);

  if (justDeleted)
    return (
      <CenterBox width="100%">
        <Text color="foreground" size={17} py="4xl">
          This post has been deleted successfully
        </Text>
      </CenterBox>
    );

  return (
    <Box
      ref={postRef}
      pl={["md", "3xl"]}
      pr={isReply ? "3xl" : ["md", "3xl"]}
      py={isReply ? "xs" : "lg"}
      borderBottom={`1px solid ${BASE_COLORS.lightGreyTransparent}`}
      bg={isActiveReply ? "primaryTransparentLight" : "transparent"}
      position="relative"
    >
      {renderPremiumOverlayIfNeeded()}
      <CardHeader
        schoolLogo={schoolLogo}
        postBy={postBy}
        universityName={universityName}
        tags={extractedMentions}
        createdAt={createdAt}
        postId={id}
        authorId={authorId}
        handlePostDelete={enableBlur}
        handleJustDeleted={handlePostDelete}
        isDeletedFeed={isDeletedFeed}
        isReply={isReply}
      />
      <Row
        py="md"
        color="black"
        onClick={() => router.push(`/post/${id}`)}
        style={{ cursor: "pointer" }}
      >
        <Box gap="sm">
          <Text size={18} weight="bold">
            {title}
          </Text>
          <Text>{renderContent()}</Text>
        </Box>
      </Row>
      <CardFooterV2
        postId={id}
        initialUpVotes={upVotes}
        initialDownVotes={downVotes}
        commentsCount={comments}
        savedPosts={savedPosts}
        alreadyUpvoted={alreadyUpVoted}
        alreadyDownVoted={alreadyDownVoted}
        alreadyBookMarked={alreadyBookMarked}
        viewCount={viewCount}
      />
    </Box>
  );
};
