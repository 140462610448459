import React, { ReactNode, useEffect, useState } from "react";
import { Auth } from "@/app/auth/Auth";
import { PostComment } from "@/components/PostComment";
import { ShareModal } from "../ShareModal";
import {
  Row,
  HoverBoxFooter,
  ImageContainer,
  HoverText,
  Box,
} from "@/components/styled";
import { BouncyModal } from "@/components/styled/BouncyModal";
import { useModal } from "@/hooks/useModal";
import { useVotePost } from "@/hooks/useVotePost";
import { useUndoVotePost } from "@/hooks/useUndoVotePost";
import { useBookMarkPost } from "@/hooks/useBookMarkPost";
import { useUndoBookMark } from "@/hooks/useUndoBookMark";
import toast from "react-hot-toast";
import { VotePostActivity } from "@/gql/graphql";

type CardFooterProps = {
  initialUpVotes: number;
  initialDownVotes: number;
  commentsCount: number;
  savedPosts: number;
  postId: string;
  alreadyUpvoted: boolean;
  alreadyDownVoted: boolean;
  alreadyBookMarked: boolean;
  viewCount: number;
};
// const getStoredCount = (key: string, postId: string): number | null => {
//   const storedCounts = localStorage.getItem(key);
//   if (storedCounts) {
//     const countsMap = JSON.parse(storedCounts);
//     return countsMap[postId] || null;
//   }
//   return null;
// };
// const setStoredCount = (key: string, postId: string, count: number): void => {
//   const storedCounts = localStorage.getItem(key);
//   let countsMap = storedCounts ? JSON.parse(storedCounts) : {};
//   countsMap[postId] = count;
//   localStorage.setItem(key, JSON.stringify(countsMap));
// };

const formatCount = (count: number): string => {
  if (count >= 1000000) return `${(count / 1000000).toFixed(0)}M`;
  if (count >= 1000) return `${(count / 1000).toFixed(1)}k`;
  return count.toString();
};

const ActionButton: React.FC<{
  icon: string;
  count?: number;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}> = ({ icon, count, onClick }) => (
  <HoverBoxFooter gap={["xs", "sm"]} onClick={onClick}>
    <ImageContainer alignItems="center" justifyContent="center">
      <Box
        backgroundImage={`url(${icon})`}
        width={["14px", "15px"]}
        height={["14px", "15px"]}
        backgroundRepeat="no-repeat"
        backgroundPosition="center"
        backgroundSize="contain"
      />
    </ImageContainer>
    {count !== undefined && (
      <HoverText fontWeight={400} color="primary">
        {formatCount(count)}
      </HoverText>
    )}
  </HoverBoxFooter>
);

export const CardFooterV2: React.FC<CardFooterProps> = ({
  initialDownVotes,
  commentsCount,
  savedPosts,
  postId,
  alreadyBookMarked,
  alreadyDownVoted,
  alreadyUpvoted,
  viewCount,
  initialUpVotes,
}) => {
  const [upVote, setUpVote] = useState(initialUpVotes);

  const [downVote, setDownVote] = useState(initialDownVotes);
  const [bookMark, setbookMark] = useState(savedPosts);
  const [isAlreadyBookMarked, setIsAlreadyBookMarked] =
    useState(alreadyBookMarked);
  const [isAlreadyUpVoted, setIsAlreadyUpvoted] = useState(alreadyUpvoted);
  const [isAlreadyDownVoted, setIsAlreadyDownvoted] =
    useState(alreadyDownVoted);

  useEffect(() => {
    setIsAlreadyUpvoted(alreadyUpvoted);
    setIsAlreadyDownvoted(alreadyDownVoted);
    setIsAlreadyBookMarked(alreadyBookMarked);
  }, [alreadyUpvoted, alreadyBookMarked, alreadyDownVoted]);

  const { votePost } = useVotePost();
  const { undoVotePost } = useUndoVotePost();
  const { bookmarkPost } = useBookMarkPost();
  const { undoBookmarkPost } = useUndoBookMark();

  const MakeUpVote = async () => {
    try {
      await votePost({
        variables: {
          input: {
            type: VotePostActivity.Upvote,
            postId: postId,
          },
        },
      });
      console.log("upvoted", upVote);
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  const MakeDownVote = async () => {
    try {
      await votePost({
        variables: {
          input: {
            type: VotePostActivity.Downvote,
            postId,
          },
        },
      });
    } catch (error) {
      console.log(error);
    }
  };
  const UndoDownVotePost = async () => {
    try {
      await undoVotePost({
        variables: {
          input: {
            type: VotePostActivity.Downvote,
            postId,
          },
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const UndoUpVotePost = async () => {
    try {
      await undoVotePost({
        variables: {
          input: {
            type: VotePostActivity.Upvote,
            postId,
          },
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const BookMarkPost = async () => {
    try {
      await bookmarkPost({
        variables: {
          input: {
            postId,
          },
        },
      });
      toast.success("Post added to bookmarks");
    } catch (error) {
      console.log(error);
    }
  };

  const UndoBookMarkPost = async () => {
    try {
      await undoBookmarkPost({
        variables: {
          input: {
            postId,
          },
        },
      });
      toast.success("Post removed from bookmarks");
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  const handleUpVote = async (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.stopPropagation();
    if (isAlreadyUpVoted) {
      setUpVote((prev) => prev - 1);
      UndoUpVotePost();
      setIsAlreadyUpvoted(false);
    } else if (isAlreadyDownVoted) {
      setDownVote((prev) => prev - 1);
      setUpVote((prev) => prev + 1);
      UndoDownVotePost();
      MakeUpVote();
      setIsAlreadyUpvoted(true);
      setIsAlreadyDownvoted(false);
    } else {
      setUpVote((prev) => prev + 1);
      MakeUpVote();
      setIsAlreadyUpvoted(true);
    }
  };
  const handleDownVote = async (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.stopPropagation();
    if (isAlreadyDownVoted) {
      setDownVote((prev) => prev - 1);
      UndoDownVotePost();
      setIsAlreadyDownvoted(false);
    } else if (isAlreadyUpVoted) {
      setUpVote((prev) => prev - 1);
      setDownVote((prev) => prev + 1);
      UndoUpVotePost();
      MakeDownVote();
      setIsAlreadyDownvoted(true);
      setIsAlreadyUpvoted(false);
    } else {
      setDownVote((prev) => prev + 1);
      MakeDownVote();
      setIsAlreadyDownvoted(true);
    }
  };

  const handleBookMark = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.stopPropagation();
    if (isAlreadyBookMarked) {
      setbookMark((prev) => prev - 1);
      UndoBookMarkPost();
      setIsAlreadyBookMarked(false);
    } else {
      setbookMark((prev) => prev + 1);
      BookMarkPost();
      setIsAlreadyBookMarked(true);
    }
  };

  const { isOpen, toggleModal } = useModal();
  const [renderModalType, setRenderModalType] = useState("");
  const [isLoggedin, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    setIsLoggedIn(!!token);
  }, []);

  const renderModalContent = (): ReactNode => {
    switch (renderModalType) {
      case "COMMENT":
        return <PostComment postId={postId} toggleModal={toggleModal} />;
      case "SHARE":
        return <ShareModal postId={postId} />;
      default:
        return <Auth />;
    }
  };

  return (
    <>
      <Row width="100%" alignItems="center">
        <Row width="100%" justifyContent="space-between" alignItems="center">
          <Row gap={["lg", "2xl"]} alignItems="center">
            <ActionButton
              icon="/assets/images/svgimages/commentsLogo.svg"
              count={commentsCount}
              onClick={() => {
                setRenderModalType("COMMENT");
                toggleModal();
              }}
            />
            <ActionButton
              icon="/assets/images/svgimages/viewsLogo.svg"
              count={viewCount}
            />
            <ActionButton
              icon={
                isAlreadyBookMarked
                  ? "/assets/images/svgimages/savedPostsLogoFilled.svg"
                  : "/assets/images/svgimages/savedPostsLogo.svg"
              }
              count={bookMark}
              onClick={(e) => (isLoggedin ? handleBookMark(e) : toggleModal())}
            />
            <ActionButton
              icon="/assets/images/svgimages/sharedPostsLogo.svg"
              onClick={() => {
                setRenderModalType("SHARE");
                toggleModal();
              }}
            />
          </Row>
          <Row gap={["lg", "2xl"]}>
            <ActionButton
              icon={
                isAlreadyUpVoted
                  ? "/assets/images/svgimages/upVoteLogoFilled.svg"
                  : "/assets/images/svgimages/upVoteLogo.svg"
              }
              count={upVote}
              onClick={(e) => (isLoggedin ? handleUpVote(e) : toggleModal())}
            />
            <ActionButton
              icon={
                isAlreadyDownVoted
                  ? "/assets/images/svgimages/downVoteLogoFilled.svg"
                  : "/assets/images/svgimages/downVoteLogo.svg"
              }
              count={downVote}
              onClick={(e) => (isLoggedin ? handleDownVote(e) : toggleModal())}
            />
          </Row>
        </Row>
      </Row>
      <BouncyModal
        isOpen={isOpen}
        onClose={toggleModal}
        width={["90%", "40%"]}
        height="fit-content"
      >
        {isLoggedin ? renderModalContent() : <Auth />}
      </BouncyModal>
    </>
  );
};
